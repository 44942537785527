import tw from 'twin.macro'
import styled from 'styled-components'

export const Footer = styled.section`
  ${tw`w-full bg-black flex flex-col text-white lg:[scroll-snap-align:end]`}
`

export const FooterTop = styled.div`
  ${tw`grid md:grid-cols-2 w-full gap-10`}
  ${tw`pt-10 px-6 lg:px-10`}
`

export const FooterTopLeft = styled.div`
  ${tw`flex flex-col gap-6`}
`

export const TitleSection = styled.div`
  ${tw`flex flex-col gap-2`}
`

export const TitleHeader = styled.h1`
  ${tw`text-4xl font-semibold`}
`

export const InfoLink = styled.a`
  ${tw`text-emerald-500 underline w-min text-base`}
`

export const MountainWrapper = styled.div`
  ${tw`[width:fit-content]`}
`

export const MountainImage = styled.img`
  ${tw`max-h-[37px] mx-auto`}
`

export const MadeInWyomingHeader = styled.h4`
  font-size: 0.7rem;
  line-height: 1rem;
  ${tw`mt-[-0.55rem] ml-3`}
`

export const NavigationSection = styled.div`
  ${tw`flex flex-col gap-4 md:text-right`}
`

export const NavigationHeader = styled.h2`
  ${tw`text-2xl font-semibold`}
`

export const NavigationList = styled.ul`
  ${tw`flex flex-col gap-2 font-light list-none`}

  a {
    ${tw`text-white no-underline`}
  }
`

export const FooterBottom = styled.div`
  ${tw`flex flex-col`}
  ${tw`pb-6 px-6 lg:px-10 pt-10 md:pt-0`}
`

export const DisclaimerSection = styled.div`
  ${tw`flex flex-col gap-2 pt-2 md:pt-6`}
`

export const DisclaimerHeader = styled.h3`
  ${tw`text-xs font-semibold`}
`

export const DisclaimerParagraph = styled.p`
  font-size: 0.625rem;
  line-height: 0.7rem;
  ${tw`font-light`}
`
export const DBAParagraph = styled.p`
  line-height: 0.7rem;
  ${tw`font-light text-base`}
`

export const NotFdicInsuredDisclosure = styled.div`
  ${tw`border-emerald-500 border-2 mx-auto text-sm py-1 px-2 mt-8`}
`

export const MmfDisclosure = styled.button`
  ${tw`border-emerald-500 border-2 mx-auto text-sm py-1 px-2 mt-8 flex flex-row items-center gap-2 uppercase`}

  > svg {
    ${tw`text-emerald-500 h-5 w-5`}
  }
`

export const BottomCopyrightLine = styled.div`
  font-size: 0.65rem;
  line-height: 0.75rem;
  ${tw`justify-self-center flex gap-3 mx-auto mt-4`}
`

export const CopyrightLineGrouping = styled.div`
  ${tw`flex gap-2 font-light text-charcoal-400 whitespace-nowrap`}

  span {
    ${tw`text-charcoal-400`}
  }

  a,
  button {
    ${tw`text-white underline font-bold cursor-pointer`}
  }
`

export const BottomBorder = styled.div`
  ${tw`w-full h-1 bg-gradient-to-r from-gold-500 to-coral-500 `}
`

export const Footnote = styled.span`
  ${tw`block`}
  span {
    font-family: 'Lora', serif;
  }
`

export const DBALink = styled.span`
  ${tw`text-emerald-500 underline cursor-pointer`}
`

export const NMLS = styled.p`
  ${tw`text-xs`}
`
