import React from 'react'
import Button from '../../button'
import * as S from './styles'
import { BannerProps } from './types'

export const Banner: React.FC<BannerProps> = ({
  variety = 'emerald',
  message,
  buttons,
  textSize,
  constrainContentWidth,
}) => (
  <S.Banner variety={variety}>
    <S.BannerInner
      textSize={textSize}
      constrainContentWidth={constrainContentWidth}
    >
      {typeof message === 'string' ? <span>{message}</span> : message}
      <S.BannerButtons>
        {Array.isArray(buttons) ? (
          buttons.map(({ title, onClick, variety, themeColor, ...rest }) => (
            <Button
              key={title}
              variety={variety ?? 'primary'}
              themeColor={themeColor ?? 'black'}
              onClick={onClick}
              {...rest}
            >
              {title}
            </Button>
          ))
        ) : (
          <Button
            {...buttons}
            variety={buttons.variety ?? 'primary'}
            themeColor={buttons.themeColor ?? 'black'}
            onClick={buttons.onClick}
          >
            {buttons.title}
          </Button>
        )}
      </S.BannerButtons>
    </S.BannerInner>
  </S.Banner>
)
