import tw from 'twin.macro'
import styled from 'styled-components'
import { BannerProps } from './types'

export const Banner = styled.div<Pick<BannerProps, 'variety'>>`
  ${tw`py-5 px-4 lg:px-8`}
  ${({ variety = 'emerald' }) =>
    variety === 'gold' ? tw`bg-gold-500` : tw`bg-emerald-500`}
`

export const BannerInner = styled.div<
  Pick<BannerProps, 'constrainContentWidth' | 'textSize'>
>`
  ${tw`flex flex-col md:flex-row items-center justify-center text-black gap-4`}
  ${({ constrainContentWidth = true }) =>
    constrainContentWidth && tw`max-w-5xl mx-auto`}
  ${({ textSize = 'base' }) =>
    textSize === 'lg' ? tw`text-lg` : tw`text-base`}

  a {
    ${tw`text-black underline`}
  }
`

export const BannerButtons = styled.div`
  ${tw`flex gap-8 w-full md:[width:fit-content] justify-end text-black`}
`
