import { ButtonProps } from './types'
import styled from 'styled-components'
import tw from 'twin.macro'

export const StyledButton = styled.button<ButtonProps>`
  ${tw`whitespace-nowrap rounded-md justify-center border-2 uppercase font-medium tracking-widest flex flex-row items-center gap-4`}

  ${({ size }) => size === 'sm' && tw`px-4 py-1 tracking-wider text-sm`}
    ${({ size }) => size === 'md' && tw`px-6 py-2 tracking-widest text-base`}
    ${({ size }) => size === 'lg' && tw`px-8 py-2 tracking-widest text-lg`}

    // Primary Button
    ${tw`text-black bg-gold-500 border-gold-500`}
    ${({ disabled }) =>
    !disabled &&
    tw`hover:bg-gold-300 hover:border-gold-300 hover:text-charcoal-700`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'black' &&
    tw`text-white bg-black border-none`}
    ${({ variety, themeColor, disabled }) =>
    variety === 'primary' &&
    themeColor === 'black' &&
    !disabled &&
    tw`hover:bg-charcoal-800 hover:text-white hover:border-none`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'gray' &&
    tw`text-white bg-charcoal-750 border-charcoal-750`}
    ${({ variety, themeColor, disabled }) =>
    variety === 'primary' &&
    themeColor === 'gray' &&
    !disabled &&
    tw`hover:bg-charcoal-750 hover:text-white hover:border-charcoal-750/[0.9] hover:bg-charcoal-750/[0.9]`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'carolina' &&
    tw`bg-carolina-500 border-carolina-500 hover:border-carolina-500/[0.9] hover:bg-carolina-500/[0.9]`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'emerald' &&
    tw`bg-emerald-500 border-emerald-500 hover:border-emerald-500/[0.9] hover:bg-emerald-500/[0.9]`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'rodeo-blue' &&
    tw`bg-carolina-700 border-carolina-700 hover:border-carolina-700/[0.9] hover:bg-carolina-700/[0.9]`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'red' &&
    tw`bg-coral-500 border-coral-500 hover:border-coral-500/[0.9] hover:bg-coral-500/[0.9]`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'topaz' &&
    tw`bg-topaz-500 border-topaz-500 hover:border-topaz-500/[0.9] hover:bg-topaz-500/[0.9]`}
    ${({ variety, themeColor }) =>
    variety === 'primary' &&
    themeColor === 'lilac' &&
    tw`bg-lilac-500 border-lilac-500 hover:border-lilac-500/[0.9] hover:bg-lilac-500/[0.9]`}
 
    // Secondary Button
    ${({ variety }) =>
    variety === 'secondary' && tw`bg-transparent text-gold-500`}
    ${({ variety, themeColor }) =>
    variety === 'secondary' &&
    themeColor === 'carolina' &&
    tw`text-carolina-500 border-carolina-500 disabled:border-gray-600 disabled:text-gray-600`}
    ${({ variety, themeColor }) =>
    variety == 'secondary' &&
    themeColor === 'emerald' &&
    tw`text-emerald-500 border-emerald-500`}
    ${({ variety, themeColor }) =>
    variety == 'secondary' &&
    themeColor === 'rodeo-blue' &&
    tw`text-carolina-700 border-carolina-700`}
    ${({ variety, themeColor }) =>
    variety == 'secondary' &&
    themeColor == 'black' &&
    tw`text-charcoal-900 border-charcoal-900`}
    ${({ variety, themeColor }) =>
    variety == 'secondary' &&
    themeColor == 'red' &&
    tw`text-coral-500 border-coral-500`}
    ${({ variety, disabled }) =>
    variety === 'secondary' &&
    !disabled &&
    tw`hover:text-gold-500 hover:bg-gold-500/[0.15]`}
    ${({ variety, disabled, themeColor }) =>
    variety === 'secondary' &&
    themeColor === 'carolina' &&
    !disabled &&
    tw`hover:text-carolina-500 hover:border-carolina-500 hover:bg-carolina-500/[0.15]`}
    ${({ variety, disabled, themeColor }) =>
    variety === 'secondary' &&
    themeColor === 'emerald' &&
    !disabled &&
    tw`hover:text-emerald-500 hover:border-emerald-500 hover:bg-emerald-500/[0.15]`}
    ${({ variety, disabled, themeColor }) =>
    variety === 'secondary' &&
    themeColor === 'rodeo-blue' &&
    !disabled &&
    tw`hover:text-carolina-700 hover:border-carolina-700 hover:bg-carolina-700/[0.15]`}
    ${({ variety, disabled, themeColor }) =>
    variety === 'secondary' &&
    themeColor === 'black' &&
    !disabled &&
    tw`hover:text-charcoal-900 hover:border-charcoal-900 hover:bg-charcoal-900/[0.15]`}
  ${({ variety, disabled, themeColor }) =>
    variety === 'secondary' &&
    themeColor === 'red' &&
    !disabled &&
    tw`hover:text-coral-500 hover:border-coral-500 hover:bg-coral-500/[0.15]`}
    
    // Transparent Button
    ${({ variety }) =>
    variety === 'transparent' && tw`bg-transparent border-0 text-white`}
    ${({ variety, disabled }) =>
    variety === 'transparent' &&
    !disabled &&
    tw`hover:text-white hover:[background-color:#ffffff33]`}


    // Text Button
    ${({ variety }) =>
    variety === 'text' && tw`bg-transparent border-0 text-white`}
    ${({ variety, disabled }) =>
    variety === 'text' &&
    !disabled &&
    tw`hover:text-white hover:bg-transparent hover:opacity-80`}
    ${({ variety, themeColor }) =>
    variety === 'text' && themeColor === 'black' && tw`text-black`}
    ${({ variety, themeColor, disabled }) =>
    variety === 'text' &&
    themeColor === 'black' &&
    !disabled &&
    tw`hover:text-black`}
    ${({ variety, themeColor, disabled }) =>
    variety === 'text' &&
    themeColor === 'emerald' &&
    !disabled &&
    tw`text-emerald-500`}
  
    ${({ variety, themeColor, disabled }) =>
    variety === 'text' &&
    themeColor === 'carolina' &&
    !disabled &&
    tw`text-carolina-500  hover:text-carolina-700`}
  
    ${({ variety, themeColor, disabled }) =>
    variety === 'text' &&
    themeColor === 'red' &&
    !disabled &&
    tw`text-coral-500 hover:text-red-700`}
  
    ${({ disabled }) => disabled && tw`cursor-not-allowed opacity-60`}
    ${({ variety }) => variety === 'text' && tw`px-2 py-1`}

    // Icon Button
    ${({ variety }) =>
    variety === 'icon' &&
    tw`bg-transparent border-0 p-0 text-white hover:cursor-pointer hover:bg-transparent`}
`

export const StyledLoader = styled.div<
  Pick<ButtonProps, 'variety' | 'themeColor'>
>(({ variety = 'primary', themeColor = 'gold' }) => [
  tw`w-6 h-6 border-4 border-solid rounded-full animate-spin`,
  // Primary Button
  variety === 'primary' && tw`border-black border-t-transparent`,
  variety === 'primary' &&
    themeColor === 'black' &&
    tw`border-white border-t-transparent`,

  // Secondary Button
  variety === 'secondary' && tw`border-gold-500 border-t-transparent`,
  variety === 'secondary' &&
    themeColor === 'carolina' &&
    tw`border-carolina-500 border-t-transparent`,

  // Transparent and Text Buttons
  (variety === 'transparent' || variety === 'text') &&
    tw`border-white border-t-transparent`,
  variety === 'text' && tw`px-0`,
])
