import React from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { OpenGraphMedia } from 'next-seo/lib/types'
import { site } from '~/config'

export interface SeoProps {
  title?: string
  description?: string
  locale?: string
  image?: OpenGraphMedia
}

export const Seo = ({ title, description, locale, image }: SeoProps) => {
  const router = useRouter()
  const url = `${site.host}${router.asPath}`
  const hydratedTitle = title ?? site.title
  const hydratedDescription = description ?? site.description
  const images: OpenGraphMedia[] =
    image != null
      ? [image]
      : [
          {
            url: `${site.host}/images/about/horses.webp`,
          },
        ]
  return (
    <NextSeo
      canonical={url}
      title={hydratedTitle}
      description={hydratedDescription}
      openGraph={{
        title: hydratedTitle,
        description: hydratedDescription,
        url,
        locale: locale ?? 'en_US',
        type: 'article',
        site_name: site.title,
        images,
      }}
    />
  )
}
