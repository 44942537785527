import { ButtonProps } from './types'
import Link from '@package/ds/link'
import React from 'react'
import * as S from './styles'

const DSButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      href,
      type = 'button',
      disabled = false,
      loading = false,
      variety = 'primary',
      themeColor = 'gold',
      icon = false,
      size = 'md',
      ...props
    },
    ref,
  ) => {
    const propsWithDefaults = {
      disabled,
      variety,
      themeColor,
      icon,
      size,
      ...props,
    }

    /**
     * If you're looking at this and thinking "that's weird" you're not alone.
     * Next.js requires their <Link> to wrap an <a>. Since the design system is
     * setup to auto provide an <a> so the developer doesn't have to remember
     * to do that, here the type="button" tells <Link> to not provide the
     * auto wrapped <a> as its being supplied here instead.
     */
    if (href) {
      return (
        <Link href={href} type="button">
          <S.StyledButton as={'a'} {...propsWithDefaults} ref={ref}>
            {children}
          </S.StyledButton>
        </Link>
      )
    }

    return (
      <S.StyledButton
        {...propsWithDefaults}
        type={type}
        disabled={disabled || loading}
        ref={ref}
      >
        {loading && (
          <S.StyledLoader variety={variety} themeColor={themeColor} />
        )}
        {children}
      </S.StyledButton>
    )
  },
)

export default DSButton
