import tw, { styled, css } from 'twin.macro'

export const gradientBackground = css`
  background: linear-gradient(
    270deg,
    #fb5341 0%,
    #d5a600 17%,
    #41cd7f 69%,
    #009789 100%
  );
`

export const Border = styled.div`
  ${gradientBackground}
  ${tw`lg:h-0.5`}
`
