import tw, { styled } from 'twin.macro'

interface ShellWrapperProps {
  enableSnapPoints?: boolean
}

export const ShellWrapper = styled.div(
  ({ enableSnapPoints = false }: ShellWrapperProps) => [
    tw`flex flex-col min-h-screen min-w-min`,
    enableSnapPoints &&
      tw`xl:[scroll-snap-type:y_proximity] [scroll-padding-top:72px] short:[scroll-snap-type:none]`,
  ],
)

export const ContentWrapper = styled.div`
  flex-grow: 1;
  ${tw`bg-white dark:bg-charcoal-800`}
`
