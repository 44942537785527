import Link from 'next/link'
import React, { ReactNode } from 'react'

export interface LinkProps {
  children?: ReactNode
  className?: string
  href?: string
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void
  type?: 'button' | 'link'
}

const DSLink = ({
  children,
  className = null,
  href,
  onClick,
  type = 'link',
  ...props
}: LinkProps): JSX.Element => {
  let Children: ReactNode = (
    <span className={className} onClick={onClick}>
      {children}
    </span>
  )

  // Look like a link but don't act like one
  if (typeof onClick == 'function') {
    return <>{Children}</>
  }

  /**
   * NOTE: In the future it would be nice to check which outer HTML element
   * is the child, and if it is an <a> there is no need to pass
   * a type prop.
   */
  if (type === 'button') {
    Children = children
  }

  /**
   * URL function was passed instead of the string result of the function
   * NOTE: Pages page will not understand this
   */
  if (
    typeof href == 'function' &&
    typeof window != 'undefined' &&
    window.location.pathname != '/pages'
  ) {
    console.error('Cannot pass url functions here; please resolve at parent.')
    console.log('href', href)
  }

  if (!href) {
    console.error('<Link> requires href prop.')
    console.log(props)
    console.log(Children)
  }

  return (
    <Link {...props} href={href} passHref>
      {Children}
    </Link>
  )
}

export default DSLink
