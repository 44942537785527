import React from 'react'
import Banner from '../banner'
import * as S from './styles'
import { useCookiePrefCookie } from './useCookiePrefCookie'

const CookieBanner = () => {
  const [, setCookie, displayCookieBanner] = useCookiePrefCookie()

  return (
    displayCookieBanner && (
      <S.CookieBannerContainer>
        <Banner
          variety={'gold'}
          constrainContentWidth={false}
          message={
            <p>
              By continuing to browse this site, you consent to the use of
              cookies or similar technologies. Please see our{' '}
              <a href={'/legal/privacy-notice/'}>Privacy Notice</a> for further
              information.
            </p>
          }
          buttons={[
            {
              title: 'Reject',
              onClick: () => {
                setCookie('rejected')
              },
              variety: 'text',
            },
            {
              title: 'Accept',
              onClick: () => {
                setCookie('accepted')
              },
              variety: 'primary',
              themeColor: 'black',
            },
          ]}
        />
      </S.CookieBannerContainer>
    )
  )
}

export default CookieBanner
